export function scrollToInvalid (element) {
  const formMarkedAsInvalid = document.getElementById('vjs-form-marked-as-invalid')
  if (!formMarkedAsInvalid) return

  if (!formMarkedAsInvalid.value) {
    if (checkIfElementIsVisible(element)) {
      element.scrollIntoView({ behavior: 'smooth' })
    } else {
      element.parentElement.scrollIntoView({ behavior: 'smooth' })
    }
    formMarkedAsInvalid.value = true
  }
}

export function unsetFormMarketAsInvalid () {
  const formMarkedAsInvalid = document.getElementById('vjs-form-marked-as-invalid')
  if (formMarkedAsInvalid) formMarkedAsInvalid.value = false
}

function checkIfElementIsVisible (element) {
  if (!element) return false

  // doesn't work on Safari, but is preferable in other browsers:
  if (element.checkVisibility) return element.checkVisibility()
  return element.offsetParent !== null
}
