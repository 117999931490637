import validateTextInput from '../validation_utils/validateTextInput'

export default function authenticationEmailFormValidation () {
  const emailForm = Array.from(document.getElementsByClassName('vjs-authenticate-email-form'))[0]

  if (emailForm) {
    ['change', 'submit'].forEach(formEvent => {
      emailForm.addEventListener(formEvent, event => {
        validateTextInput(event, 'vjs-user-email', setEmailValidationErrorText)
      })
    })
  }
}

function setEmailValidationErrorText (validityObject, errorEl) {
  const { valueMissing, typeMismatch } = validityObject

  if (valueMissing) {
    errorEl.textContent = 'You need to enter an e-mail address'
  } else if (typeMismatch) {
    errorEl.textContent = 'The entered value is not an e-mail address'
  }
}
