import { scrollToInvalid } from './scrollToInvalid'

export default function validateTextInput (submitEvent, fieldId, functionToHandleErrorMessages) {
  const label = document.querySelector(`label[for="${fieldId}"]`)
  const field = document.getElementById(fieldId)
  const errorEl = document.querySelector(`#${fieldId} ~ .vsd-form-field__error`)

  if (!field) return

  const validityObject = field.validity

  if (validityObject.valid) {
    styleInputAsValid(label, field, errorEl)
  } else {
    submitEvent.preventDefault()
    styleInputAsInvalid(label, field, errorEl)
    functionToHandleErrorMessages(validityObject, errorEl)
  }
}

function styleInputAsValid (label, inputEl, errorEl) {
  inputEl.classList.remove('vsd-form-field--invalid')
  errorEl.textContent = ''
  errorEl.classList.remove('vsd-form-field__error--visible')
  if (label) label.className = ''
}

function styleInputAsInvalid (label, inputEl, errorEl) {
  inputEl.classList.add('vsd-form-field--invalid')
  errorEl.classList.add('vsd-form-field__error--visible')

  if (label) {
    label.className = 'vsd-form-label--invalid'
    scrollToInvalid(label)
  } else {
    scrollToInvalid(inputEl)
  }
}
