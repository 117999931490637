import { scrollToInvalid } from './scrollToInvalid'

export default function validateSelect (submitEvent, selectId, functionToHandleErrorMessages) {
  const labelEl = document.querySelector(`label[for="${selectId}"]`)
  const selectEl = document.getElementById(selectId)
  const errorEl = document.querySelector(`#${selectId} ~ .vsd-form-field__error`)
  const validityObject = selectEl.validity
  const proxySelectEl = document.querySelector(`#${selectId} ~ .vjs-proxy-select__display`)

  if (validityObject.valid) {
    styleSelectAsValid(labelEl, proxySelectEl, errorEl)
  } else {
    submitEvent.preventDefault()
    styleSelectAsInvalid(labelEl, proxySelectEl, errorEl)
    functionToHandleErrorMessages(validityObject, errorEl)
  }
}

function styleSelectAsValid (labelEl, proxySelectEl, errorEl) {
  if (labelEl) labelEl.className = ''
  proxySelectEl.classList.remove('vsd-form-field--invalid')
  errorEl.textContent = ''
  errorEl.classList.remove('vsd-form-field__error--visible')
}

function styleSelectAsInvalid (labelEl, proxySelectEl, errorEl) {
  if (labelEl) labelEl.className = 'vsd-form-label--invalid'
  proxySelectEl.classList.add('vsd-form-field--invalid')
  errorEl.classList.add('vsd-form-field__error--visible')
  scrollToInvalid(labelEl)
}
