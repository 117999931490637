import validateTextInput from '../validation_utils/validateTextInput'
import validateSelect from '../validation_utils/validateSelect'

export default function tradeBuyerFormValidation () {
  const tradeBuyerForm = Array.from(document.getElementsByClassName('vjs-trade-buyer-form'))[0]
  const textInputs = [
    'vjs-user-account-attributes-phone-number',
    'vjs-user-trade-buyer-account-attributes-company-name',
    'vjs-user-trade-buyer-account-attributes-current-project'
  ]
  const selectMenus = [
    'vjs-user-trade-buyer-account-attributes-business-type',
    'vjs-user-trade-buyer-account-attributes-company-country',
    'vjs-user-trade-buyer-account-attributes-company-spend'
  ]

  if (!tradeBuyerForm) return

  textInputs.forEach(inputId => {
    document.getElementById(inputId).addEventListener('blur', e => {
      validateTextInput(e, inputId, setErrorText)
    })
  })

  selectMenus.forEach(selectId => {
    document.getElementById(selectId).addEventListener('change', e => {
      validateSelect(e, selectId, setErrorText)
    })
  })

  tradeBuyerForm.addEventListener('submit', e => {
    validateOnSubmit(e, textInputs, selectMenus)
  })
}

function validateOnSubmit (e, textInputs, selectMenus) {
  textInputs.forEach(inputId => validateTextInput(e, inputId, setErrorText))
  selectMenus.forEach(selectId => validateTextInput(e, selectId, setErrorText))
}

function setErrorText (validityObject, errorEl) {
  const { valueMissing } = validityObject
  if (valueMissing) errorEl.textContent = 'This field is required'
}
