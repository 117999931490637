import emailFormValidation from '../../src/form_validations/emailFormValidation'
import passwordFormValidation from '../../src/form_validations/passwordFormValidation'
import forgottenPasswordFormValidation from '../../src/form_validations/forgottenPasswordFormValidation'
import registrationFormValidation from '../../src/form_validations/registrationFormValidation'
import tradeBuyerFormValidation from '../../src/form_validations/tradeBuyerFormValidation'

document.addEventListener('DOMContentLoaded', () => {
  emailFormValidation()
  passwordFormValidation()
  forgottenPasswordFormValidation()
  registrationFormValidation()
  tradeBuyerFormValidation()
})
