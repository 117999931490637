import validateTextInput from '../validation_utils/validateTextInput'

export default function authenticationForgottenPasswordFormValidation () {
  const forgottenPasswordForm = Array.from(document.getElementsByClassName('vjs-forgotten-password-form'))[0]

  if (forgottenPasswordForm) {
    forgottenPasswordForm.addEventListener('submit', event => {
      validateTextInput(event, 'vjs-user-email', setEmailValidationErrorText)
    })
  }
}

function setEmailValidationErrorText (validityObject, errorEl) {
  const { valueMissing, typeMismatch } = validityObject

  if (valueMissing) {
    errorEl.textContent = 'You need to enter an e-mail address'
  } else if (typeMismatch) {
    errorEl.textContent = 'The entered value is not an e-mail address'
  }
}
