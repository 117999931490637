import validateTextInput from '../validation_utils/validateTextInput'

export default function authenticationPasswordFormValidation () {
  const passwordForm = Array.from(document.getElementsByClassName('vjs-authenticate-password-form'))[0]

  if (passwordForm) {
    ['change', 'submit'].forEach(formEvent => {
      passwordForm.addEventListener(formEvent, event => {
        validateTextInput(event, 'vjs-user-password', setPasswordValidationErrorText)
      })
    })
  }
}

function setPasswordValidationErrorText (validityObject, errorEl) {
  const { valueMissing } = validityObject

  if (valueMissing) errorEl.textContent = 'You need to enter a password'
}
